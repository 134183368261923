import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios';
axios.defaults.withCredentials = true;

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    /// Session states
    user: undefined,

    /// Theming states
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg',
    drawer: null,

    /// Persistent data
    organizations: [ ], //NOTE: Only populated for superadmins
  },

  getters: {
    user(state) {
      return state.user;
    },

    organizations(state) {
      return state.organizations;
    }
  },

  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_SCRIM (state, payload) {
      state.barColor = payload
    },

    saveOrganizations(state, data) {
      state.organizations = data;
    },

    initSessionData(state) {
      if (state.user) return;//Session data already loaded, no need to do that again.
      let data = localStorage.getItem('qrpay-portal-session-data');
      try {
        data = JSON.parse(data);
      } catch(jsonParseException) {
        console.error(`Session data is invalid, or absent.`);
        return;
      }
      if (data) {
        state.user = data;
      }
    },
    login(state, user) {
      state.user = user;
      localStorage.setItem('qrpay-portal-session-data', JSON.stringify(user));
    },
    logout(state) {
      state.user = undefined;
      localStorage.removeItem('qrpay-portal-session-data');
    }
  },

  actions: {

    // Data syncing methods

    fetchOrganizations({ state, commit }) {
      if (state.user.level !== 'superadmin')
        return;
      return axios.get(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/orgs`)
      .then(response => {
        commit('saveOrganizations', response.data);
        return response.data;
      })
      .catch(err => {
        console.error(`[Vuex Store] fetchOrganizations error`, err);
      });
    },

    // Signin & signout methods

    requestSignin({ state, commit }, { organization, username, password }) {
      // let { organization, username, password } = args;

      return axios.post(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/signin`,
        { organization, username, password }
      )
      .then(response => {
        console.info(`Sign-in succeeded`, response.data);
        commit('login', response.data.user);
      })
      .catch(err => {
        console.error(`Failed to sign-in`, err);
        commit('logout');
        throw err
      });
    },

    requestSignout({ state, commit }, args) {
      return axios.post(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/signout`)
      .then(response => {
        console.info(`Sign-out succeeded`, response.data);
      })
      .catch(err => {
        if (err.response && err.response.status === 401) {}
        else {
          console.error(`Failed to sign-out`, err);
          throw err;
        }

      })
      .finally(() => {
        commit('logout');
      });
    }
  },
})
