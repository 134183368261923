import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@mdi/font/css/materialdesignicons.css';
import '@/sass/overrides.sass'

Vue.use(Vuetify)

const theme = {
  primary: '#9C27b0',
  secondary: '#E91E63',
  accent: '#BA68C8',
  info: '#00CAE3',
}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
  icons: {
    iconfont: 'mdi',
  }
})
