import Vue from 'vue'
import VueI18n from 'vue-i18n'

// import ar from 'vuetify/lib/locale/ar'
import en from 'vuetify/lib/locale/en'
// import tw from 'vuetify/lib/locale/tw'
import zhHant from 'vuetify/lib/locale/zh-Hant'

Vue.use(VueI18n)

const messages = {
  'en-US': {
    ...require('@/locales/en.json'),
    $vuetify: en,
  },
  // ar: {
  //   ...require('@/locales/ar.json'),
  //   $vuetify: ar,
  // },
  'zh-TW': {
    ...require('@/locales/tw.json'),
    $vuetify: zhHant,
  },
}

export default new VueI18n({
  // locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  locale: navigator.language || 'en-US',
  // fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en-US',
  fallbackLocale: 'en-US',
  messages,
})
