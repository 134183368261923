<template>
  <div>
    <router-view />

    <notifications position="top right" :duration="5000" classes="vue-notification mt-4"/>
  </div>
</template>

<script>
export default {
  name: 'App',

  created() {
    this.$http.interceptors.response.use(undefined, err => {
      return new Promise((resolve, reject) => {
        console.error(`[HTTP INTERCEPTOR] Caught error response`, err.response);
        if (err.response && err.response.status === 401) {
          // Check if the current route is not the sign-in page
          if (this.$router.currentRoute.path !== '/signin') {
            console.log('the currentRoute is ', this.$router.currentRoute.path)
            this.$store.dispatch('requestSignout')
              .finally(() => {
                this.$router.push('/signin');
              });
          }
        }
        throw err;
      });
    });
  }
}
</script>
