// =========================================================
// * Vuetify Material Dashboard PRO - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import './plugins/vue-world-map'
import vuetify from './plugins/vuetify'
import i18n from './i18n'

import Notifications from 'vue-notification';
import SimpleVueValidation from 'simple-vue-validator';
import VueSweetalert2 from 'vue-sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';

import axios from 'axios';
Vue.prototype.$http = axios;

Vue.config.productionTip = false

Vue.use(Notifications);
Vue.use(SimpleVueValidation);
Vue.use(VueSweetalert2);

// Configure routing conditions.
router.beforeEach(async(to, from, next) => {
  console.warn('router.beforeEach', from, to, store.state.user);
  //FIXME: This assumes all pages require login.
  if (to.name != 'Signin') {
    store.commit('initSessionData');
    if (store.state.user && store.state.user.username) {
      console.warn(`Has user session data, continue.`);
      next();
    }
    else {
      // 這邊檢查session
      const response = await axios.get(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/me`);
      const user = response.data ? response.data : null;
      console.log('check result and commit to local store ', response.data);
      if (user.user && user.user.id) {
        store.commit('login', user.user);
        next();
      } else {
        console.warn(`No user session data, go to login page.`);
        next({ path: '/signin' });
      }


    }
  }
  else {
    next();
  }
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')

console.log('navigation.language', navigator.language);
